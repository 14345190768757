.typography {
}

h1.typography,
h2.typography,
h3.typography,
h4.typography,
h5.typography,
h6.typography {
  line-height: 1.1;
}

/* CSS */
.typography-sm {
  font-size: 14px;
}

@media (max-width: 300px) {
  .typography-sm {
    font-size: 11px !important;
  }
  .typography-md {
    font-size: 14px !important;
  }
  .typography-lg {
    font-size: 17px !important;
  }
  .typography-xl {
    font-size: 24px !important;
  }
}
@media (min-width: 768px) {
  .typography-sm {
    font-size: 16px;
  }
}

.typography-md {
  font-size: 16px;
}

@media (min-width: 768px) {
  .typography-md {
    font-size: 18px;
  }
}

.typography-lg {
  font-size: 18px;
}

@media (min-width: 768px) {
  .typography-lg {
    font-size: 20px;
  }
}

.typography-xl {
  font-size: 36px;
}

@media (min-width: 768px) {
  .typography-xl {
    font-size: 46px;
  }
}
